<template>
  <div v-if="!isLoading">
    <!-- {{ cards }}
    <li :key="i" v-for="(c, i) in cards">
      {{ i }} => {{c.id}} - {{ stats[c.id] }}
      </li> -->

      <section v-if="cards" id="dash">
        <div class="row q-mx-auto q-pt-md justify-start items-start">
          <div class="flex q-gutter-md">
            <DashCard
              :key="i"
              :stats="stats[c.id]"
              :card="c"
              v-for="(c, i) in cards"
            />
          </div>
        </div>
      </section>

      <section id="card-holder">
        <div class="row q-mx-auto q-pb-md">
          <div class="col">
            <span class="text-h3 text-white">10 {{ context.label }}</span>
          </div>
          <div class="col-shrink">
            <q-select
              dense
              label-color="white"
              v-model="context"
              :options="stats.tops"
              filled
              option-value="value"
              option-label="label"
            />
          </div>
        </div>

        <div class="row q-mx-auto justify-start items-start nosources">
          <div class="flex q-gutter-md">
            <Card
              :key="i"
              v-for="(c, i) in stats.topdata[context.value]"
              :imageUrl="c.image"
              ctype="image"
              type="Source"
              csstype="source"
              :title="c.title"
            >
            </Card>
          </div>
        </div>
      </section>
    </li>
  </div>
</template>
<script>
import DashCard from "@/components/DashCard";
import { API } from "@/tic";
import { kk } from "date-fns/locale";
const dash = [
  {
    id: "narratives",
    icon: "fa-books",
    title: "Narratives",
    action: "/my/narratives",
    about: "Narratives are your content wrapped in a story long or short.",
  },
  {
    id: "narrativesources",
    icon: "fa-bookopen-cover",
    title: "Sources used in Narratives",
    action: "/my/narratives",
    about: "Narratives are your content wrapped in a story long or short.",
  },
  {
    id: "activities",
    icon: "fa-pencil",
    title: "E-Activities",
    // add: "/some/link",
    action: "/my/activities",
    about: "Interactive materials with your sources",
  },
  {
    id: "collections",
    // icon: "fa-rectangle-history",
    icon: "fa-layer-group",
    title: "Collections",
    // add: "/some/link",
    action: "/my/collections",
    about: "Your sources grouped by any theme or keyword into a collection.",
  },
  {
    id: "sources",
    icon: "fa-grid-horizontal",
    title: "Sources",
    // add: "/some/link",
    action: "/my/sources",
    about: "Your visuals or textual assets",
  },
  {
    id: "tags",
    icon: "fa-tag",
    title: "Tags",
    // add: "/some/link",
    action: "/my/tags",
    about: "Custom tags to identify your assets",
  },
  {
    id: "shares",
    icon: "fa-share",
    title: "Shares",
    // add: "/some/link",
    action: "/my/shares",
    about: "What content is shared with whom.",
  },
  {
    id: "members",
    icon: "fa-users",
    title: "Team Members",
    // add: "/some/link",
    action: "/some/link",
    about: "Manage your partnership and your team.",
  },
];
import Card from "../tags/Card";

export default {
  name: "DashBoard",
  components: { DashCard, Card },
  mounted() {
    const vm = this;

    API.post("/partners/dashboard", { uuid: this.$store.state.user.uuid }).then(
      (r) => {
        console.log("api reply: ", r);
        vm.stats = { ...r.data.dash };
        vm.context = [...r.data.dash.tops].pop();
        console.log("default context set to: ", vm.context);
        vm.isLoading = false;
      }
    );
  },
  data() {
    return {
      isLoading: true,
      cards: { ...dash },
      stats: {},
      context: null,
    };
  },
};
</script>


